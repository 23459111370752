.filter {
    font-family: "Ubuntu Condensed", serif;
    overflow: hidden;
    background-color: #e5edeb;
    display: flex;
    justify-content: space-around
}

.filter .filter-item {
    width: 33%;
    padding: 10px 0;
    list-style: none
}

.filter .filter-item > span {
    font-size: 12px;
    display: block;
    width: auto;
    margin: 0 5px 0 5px;
    padding: 3px;
    text-align: center;
    text-transform: uppercase;
    color: #010101;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer
}

.filter .filter-item > span:hover {
    color: #fff;
    background-color: #804b77
}

.filter .filter-item.active > span {
    color: #fff;
    background-color: #804b77
}

.status {
    font-family: "Ubuntu Condensed", serif;
    font-size: 10px;
    font-weight: 400;
    display: initial;
    color: #fff;
    padding: 3px 5px;
    margin: 0 5px;
    border-radius: 5px
}
