@font-face {
    font-family: mayonezbold;
    font-weight: 400;
    font-style: normal;
    src: url('//goloskarpat.info/misc/fonts/mayonez/MayonezlBold.otf');
    font-display: swap
}

@font-face {
    font-family: mayonezitalic;
    font-weight: 400;
    font-style: normal;
    src: url('//goloskarpat.info/misc/fonts/mayonez/MayonezlItalic.otf');
    font-display: swap
}

@font-face {
    font-family: proxima-nova;
    src: url('//goloskarpat.info/misc/fonts/proximanova/ProximaNovaRegular/ProximaNovaRegular.eot');
    src: url('//goloskarpat.info/misc/fonts/proximanova/ProximaNovaRegular/ProximaNovaRegular.eot?#iefix') format("embedded-opentype"),
    url('//goloskarpat.info/misc/fonts/proximanova/ProximaNovaRegular/ProximaNovaRegular.woff') format("woff"),
    url('//goloskarpat.info/misc/fonts/proximanova/ProximaNovaRegular/ProximaNovaRegular.ttf') format("truetype");
    font-style: normal;
    font-weight: 300;
    font-display: swap
}

@font-face {
    font-family: proxima-nova;
    src: url(//goloskarpat.info/misc/fonts/proximanova/ProximaNovaBold/ProximaNovaBold.eot);
    src: url(//goloskarpat.info/misc/fonts/proximanova/ProximaNovaBold/ProximaNovaBold.eot?#iefix) format("embedded-opentype"), url(//goloskarpat.info/misc/fonts/proximanova/ProximaNovaBold/ProximaNovaBold.woff) format("woff"), url(//goloskarpat.info/misc/fonts/proximanova/ProximaNovaBold/ProximaNovaBold.ttf) format("truetype");
    font-style: normal;
    font-weight: 700;
    font-display: swap
}

@font-face {
    font-family: ProximaNovaBlack;
    src: url(//goloskarpat.info/misc/fonts/proximanova/ProximaNovaBlack/ProximaNovaBlack.eot);
    src: url(//goloskarpat.info/misc/fonts/proximanova/ProximaNovaBlack/ProximaNovaBlack.eot?#iefix) format("embedded-opentype"), url(//goloskarpat.info/misc/fonts/proximanova/ProximaNovaBlack/ProximaNovaBlack.woff) format("woff"), url(//goloskarpat.info/misc/fonts/proximanova/ProximaNovaBlack/ProximaNovaBlack.ttf) format("truetype");
    font-style: normal;
    font-weight: 400;
    font-display: swap
}

@font-face {
    font-family: 'Ubuntu Mono';
    src: local("Ubuntu Mono"), local("Ubuntu Mono Bold"), url(//64424.selcdn.ru/webfont/ubuntu/ubuntumono-b.woff) format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Ubuntu Mono';
    src: local("Ubuntu Mono"), local("Ubuntu Mono Bolt Italic"), url(//64424.selcdn.ru/webfont/ubuntu/ubuntumono-bi.woff) format("woff");
    font-weight: 700;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: 'Ubuntu Mono';
    src: local("Ubuntu Mono"), local("Ubuntu Mono Regular"), url(//64424.selcdn.ru/webfont/ubuntu/ubuntumono-r.woff) format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: 'Ubuntu Mono';
    src: local("Ubuntu Mono"), local("Ubuntu Mono Italic"), url(//64424.selcdn.ru/webfont/ubuntu/ubuntumono-ri.woff) format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: swap
}



html {
    font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji
}

article ul > li {
    @apply list-disc list-inside ml-2;
}

article ol > li {
    @apply list-decimal list-inside ml-2;
}

article h2 {
    @apply text-2xl pb-2 pt-4 font-bold;
}

article h3, h4, h5 {
    @apply text-xl pb-2 pt-4 font-bold;
}

article p {
    @apply mt-2;
}
