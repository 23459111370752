@import "./components/filter.css";
@layer components {
    /*
    .desktop-stream .stream-image img {
        max-width:none !important;
    }
    */


    .text-body a {
        @apply font-bold text-gkGreenDark hover:text-gkGreen;
    }
    .text-body blockquote {
        @apply block pl-4 pr-2 py-2 border-l-4 border-l-gray-500 bg-gray-200 font-gkSubHeader
    }

    .dark .text-body blockquote {
        @apply border-l-gray-900 bg-gray-800;
    }

    .text-body table td, .text-body table th  {
        @apply border border-gray-400 p-1;
    }
}

